import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createActivity,
  updateActivity,
  getActivity,
  deleteActivity,
} from "../../../api/server";
import { unflatten } from "flat";
import { withNotifications } from "../../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeActivity: {},
  changedItems: {
    changed: [],
    values: {},
  },
  assets: [],
  tasksFilters: {},
  tasksQueryFilters: null,
};

export const loadRecord = createAsyncThunk(
  "activity/load",
  async (params, thunkAPI) => {
    return getActivity(params).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load activity", error);
      }
      return data;
    });
  }
);

export const commitChanges = createAsyncThunk(
  "activity/update",
  withNotifications(
    async (params, thunkAPI) => {
      const { activity } = thunkAPI.getState();
      const { activeActivity } = activity;
      const { id } = activeActivity;
      const {
        changedItems: { changed, values },
      } = activity;
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});
      return updateActivity({
        activity: { id, ...unflatten(toUpdate) },
      });
    },
    "save_activity",
    "save_activity_success",
    "save_activity_error"
  )
);

export const completeTask = createAsyncThunk(
  "activity/complete_task",
  withNotifications(
    async (params, thunkAPI) => {
      return updateActivity(params);
    },
    "complete_task",
    "complete_task_success",
    "complete_task_error"
  )
);

export const createRecord = createAsyncThunk(
  "activity/create",
  withNotifications(
    createActivity,
    "create_activity",
    "create_activity_success",
    "create_activity_error"
  )
);

export const updateRecord = createAsyncThunk(
  "activity/update",
  withNotifications(
    updateActivity,
    "update_activity",
    "update_activity_success",
    "update_activity_error"
  )
);

export const cancelRecord = createAsyncThunk(
  "activity/cancel",
  withNotifications(
    updateActivity,
    "cancel_activity",
    "cancel_activity_success",
    "cancel_activity_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "activity/cancel",
  withNotifications(
    updateActivity,
    "delete_activity",
    "delete_activity_success",
    "delete_activity_error"
  )
);

const activitiesSlice = createSlice({
  name: "activity",
  initialState: initialState,
  reducers: {
    setActiveActivity(state, action) {
      const { record } = action.payload;
      state.activeActivity = record;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    setTasksFilters(state, action) {
      state.tasksFilters = action.payload;
    },
    setTasksQueryFilters(state, action) {
      state.tasksQueryFilters = action.payload;
    },
    reset(state, action) {
      state.error = null;
      state.activeActivity = {};
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.changedItems = { changed: [], values: {} };
      state.activeActivity = action.payload;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeActivity = data;
      state.changedItems = { changed: [], values: {} };
      state.error = error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeActivity = data;
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [updateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [updateRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [completeTask.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [completeTask.rejected]: (state, action) => {
      state.error = action.error;
    },
    [cancelRecord.fulfilled]: (state, action) => {
      state.activeActivity = {};
    },
  },
});

export const {
  setActiveActivity,
  reset,
  setChangedFormItems,
  setTasksFilters,
  setTasksQueryFilters,
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
