import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listScheduling,
  listEmployeesMatching,
  createOrChangeSchedulingEvent,
  deleteSchedulingEvents,
  unlockSchedulingEvents,
  changeEventTarrif,
  approveSchedulingEvent,
  transferScheduling,
  editBatchSchedulingEvents,
} from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import { withNotifications } from "../notifications/notificationsSlice";

export const fetchScheduling = createAsyncThunk(
  "scheduling/load",
  async (listParams, thunkAPI) => {
    const { error, data } = await listScheduling(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load scheduling", message: error })
      );
    }
    return data;
  }
);

export const fetchEmployeesMatching = createAsyncThunk(
  "scheduling/loadEmployeesMatching",
  async (listParams, thunkAPI) => {
    const { error, data } = await listEmployeesMatching(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load employees matching", message: error })
      );
    }
    return data;
  }
);

export const createSchedulingEvent = createAsyncThunk(
  "scheduling/create",
  withNotifications(
    createOrChangeSchedulingEvent,
    "create_scheduling_event",
    "create_scheduling_event_success",
    "create_scheduling_event_error"
  )
);

export const editSchedulingEvent = createAsyncThunk(
  "scheduling/edit",
  withNotifications(
    createOrChangeSchedulingEvent,
    "edit_scheduling_event",
    "edit_scheduling_event_success",
    "edit_scheduling_event_error"
  )
);

export const deleteSchedulingEvent = createAsyncThunk(
  "scheduling/delete",
  withNotifications(
    createOrChangeSchedulingEvent,
    "delete_scheduling_event",
    "delete_scheduling_event_success",
    "delete_scheduling_event_error"
  )
);

export const deleteEvents = createAsyncThunk(
  "scheduling/delete_events",
  withNotifications(
    deleteSchedulingEvents,
    "delete_scheduling_events",
    "delete_scheduling_events_success",
    "delete_scheduling_events_error"
  )
);

export const unlockEvents = createAsyncThunk(
  "scheduling/unlock_events",
  withNotifications(
    unlockSchedulingEvents,
    "unlock_scheduling_events",
    "unlock_scheduling_events_success",
    "unlock_scheduling_events_error"
  )
);

export const changeClientTariff = createAsyncThunk(
  "scheduling/changeTariff",
  withNotifications(
    changeEventTarrif,
    "change_event_tariff",
    "change_event_tariff_success",
    "change_event_tariff_error"
  )
);

export const approveEvent = createAsyncThunk(
  "scheduling/approveSchedulingEvent",
  withNotifications(
    approveSchedulingEvent,
    "approve_scheduling_event",
    "approve_scheduling_event_success",
    "approve_scheduling_event_error"
  )
);

export const transferEmployeeScheduling = createAsyncThunk(
  "scheduling/transferEmployeeScheduling",
  withNotifications(
    transferScheduling,
    "transfer_scheduling",
    "transfer_scheduling_event_success",
    "transfer_scheduling_event_error"
  )
);

export const transferSchedulingEvents = createAsyncThunk(
  "scheduling/transfer_events",
  withNotifications(
    editBatchSchedulingEvents,
    "transfer_scheduling_events",
    "transfer_scheduling_events_success",
    "transfer_scheduling_events_error"
  )
);

const initialPageSize = 30;
const initialState = {
  page: {
    current: 1,
    pageSize: 300,
    filter: {},
    items: [],
    effective_orders: [],
  },
  historyPage: {
    current: 1,
    pageSize: 100,
  },
  employeesMatching: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      $and: [
        {
          staff_member: {
            $neq: true,
          },
        },
      ],
    },
    items: [],
    query: {
      available_from: null,
      available_duration: null,
      classifications: [],
      longtitude: null,
      latitude: null,
    },
  },
};

const schedulingSlice = createSlice({
  name: "scheduling",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setHistoryPage(state, { payload: page }) {
      state.historyPage = page;
    },
    setEmployeesMatching(state, { payload: page }) {
      state.employeesMatching = page;
    },
    reset(state, action) {
      state.page = initialState.page;
      state.historyPage = initialState.historyPage;
    },
  },
  extraReducers: {
    [fetchScheduling.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchScheduling.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [fetchEmployeesMatching.fulfilled]: (state, action) => {
      state.employeesMatching = {
        ...state.employeesMatching,
        ...action.payload,
      };
    },
    [fetchEmployeesMatching.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createSchedulingEvent.fulfilled]: (
      state,
      { payload: { error, data } }
    ) => {
      state.error = error;
    },
    [createSchedulingEvent.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editSchedulingEvent.fulfilled]: (state, action) => {
      state.error = action.error;
    },
    [editSchedulingEvent.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteSchedulingEvent.fulfilled]: (state, action) => {
      // const { deleted } = action.meta.arg.schedulingEvent;
      // state.page.items = state.page.items.filter((item) => item.id !== deleted);
      state.error = action.error;
    },
    [deleteSchedulingEvent.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [changeClientTariff.fulfilled]: (state, action) => {
      state.error = action.error;
    },
    [changeClientTariff.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [approveEvent.fulfilled]: (state, action) => {
      state.error = action.error;
    },
    [approveEvent.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [transferEmployeeScheduling.fulfilled]: (state, action) => {
      state.error = action.error;
    },
    [transferEmployeeScheduling.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});
export const { setPage, setHistoryPage, setEmployeesMatching, reset } =
  schedulingSlice.actions;

export default schedulingSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().scheduling.page,
    ...params,
  };
  const scopePrefix = getState().user.scopePrefix;
  const patientId = getState().patientDetails.activePatient.id;
  const branchId = getState().patientDetails.activePatient.branch;
  if (patientId && branchId) {
    dispatch(setPage(newState));
    dispatch(
      fetchScheduling({ ...newState, scopePrefix, patientId, branchId })
    );
  }
};

export const loadHistory = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().scheduling.historyPage,
    ...params,
  };
  dispatch(setHistoryPage(newState));
  // const scopePrefix = getState().user.scopePrefix;
  // const patientId = getState().patientDetails.activePatient.id;
  // const branchId = getState().patientDetails.activePatient.branch;
  // if (patientId && branchId) {
  //   dispatch(setPage(newState));
  //   dispatch(
  //     fetchScheduling({ ...newState, scopePrefix, patientId, branchId })
  //   );
  // }
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().scheduling.page));
};

export const loadEmployeesMatching = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().scheduling.employeesMatching,
    ...params.page,
  };
  const branchId = params.patient.branch;
  const patientId = params.patient.id;

  const scopePrefix = `branches/${branchId}/`;
  dispatch(setEmployeesMatching(newState));
  dispatch(
    fetchEmployeesMatching({
      ...newState,
      query: {
        ...newState.query,
        available_from: new Date(newState.query.available_from),
        patient_id: patientId,
      },
      scopePrefix,
      branchId,
    })
  );
};
