import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listGaps,
  getGaps,
  sendDiariesReminders,
  createGapComment,
  updateGapComment,
  deleteGapComment,
} from "../../api/server";
import {
  notifyError,
  withNotifications,
} from "../notifications/notificationsSlice";
import moment from "moment";

export const fetchPage = createAsyncThunk(
  "gaps/loadPage",
  async (listParams, thunkAPI) => {
    const { gaps } = thunkAPI.getState();
    const queryFreeText = listParams?.filter?.freeText;
    const stateFreeText = gaps.page.filter.freeText;
    const { error, data } =
      listParams.resource === "overlaps"
        ? await getGaps(listParams)
        : await listGaps(listParams);

    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load gaps", message: error }));
    }
    return queryFreeText === stateFreeText ? data : null;
  }
);

export const updateRecordComment = createAsyncThunk(
  "gaps/update_comment",
  withNotifications(
    updateGapComment,
    "update_gap_comment",
    "update_gap_comment_success",
    "update_gap_comment_error"
  )
);

export const createRecordComment = createAsyncThunk(
  "gaps/create_comment",
  withNotifications(
    createGapComment,
    "create_gap_comment",
    "create_gap_comment_success",
    "create_gap_comment_error"
  )
);

export const deleteRecordComment = createAsyncThunk(
  "gaps/delete_comment",
  withNotifications(
    deleteGapComment,
    "delete_gap_comment",
    "delete_gap_comment_success",
    "delete_gap_comment_error"
  )
);

export const sendReminders = createAsyncThunk(
  "gaps/send_diaries_reminders",
  withNotifications(
    async (params, thunkAPI) => {
      const { scopePrefix } = thunkAPI.getState().user;
      const { page } = thunkAPI.getState().gaps;
      return sendDiariesReminders({
        page: {
          filter: page.filter,
          order: page.order,
          pageSize: page.pageSize,
          current: page.current,
        },
        query: { ...page.query },
        scopePrefix,
      });
    },
    "send_diaries_reminders",
    "send_diaries_reminders_success",
    "send_diaries_reminders_error"
  )
);

const initialPageSize = 10;
const initialState = {
  activeGap: null,
  staffId: null,
  clientId: null,
  activeDate: moment().format(),
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      field: "scheduled_minutes_diff",
      order: "ascend",
    },
    items: [],
  },
};

const gapsSlice = createSlice({
  name: "gaps",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setActiveGap(state, action) {
      const { gap } = action.payload;
      state.activeGap = gap;
      state.page = {
        ...initialState.page,
        items: [],
        order:
          gap === "ok2go" || gap === "overlaps"
            ? {}
            : {
                field: "scheduled_minutes_diff",
                order: "ascend",
              },
      };
    },
    setStaffInCharge(state, action) {
      const { staffId } = action.payload;
      state.staffId = staffId;
    },
    setClient(state, action) {
      const { clientId } = action.payload;
      state.clientId = clientId;
    },
    setDate(state, action) {
      const { date } = action.payload;
      state.activeDate = date;
    },
    setTextFilter(state, action) {
      const { text } = action.payload;
      state.page = {
        ...state.page,
        filter: {
          ...state.page.filter,
          freeText: text,
        },
      };
    },
    reset(state, action) {
      state.page = initialState.page;
      state.activeGap = initialState.activeGap;
      state.staffId = initialState.staffId;
      state.clientId = initialState.clientId;
      state.activeDate = initialState.activeDate;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      if (action.payload) {
        state.page = { ...state.page, ...action.payload };
      }
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setPage,
  setActiveGap,
  setStaffInCharge,
  setClient,
  setDate,
  setTextFilter,
  reset,
} = gapsSlice.actions;

export default gapsSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().gaps.page,
    ...params,
    filter: getResorceFilter(
      getState().gaps.activeGap,
      getState().gaps.activeDate,
      getState().gaps.page.filter.freeText
    ),
    resource: getResorce(getState().gaps.activeGap),
    query:
      getState().gaps.activeGap === "overlaps"
        ? { ...getQuery(getState().gaps.activeGap, getState().gaps.activeDate) }
        : {
            ...getQuery(getState().gaps.activeGap),
            staff_in_charge_id: getState().gaps.staffId,
            client_id: getState().gaps.clientId,
            from: moment(getState().gaps.activeDate).startOf("month").format(),
            to: moment(getState().gaps.activeDate).endOf("month").format(),
          },
  };
  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix }));
};

const getResorceFilter = (gap, date, freeText) => {
  const month = moment(date).format("M");
  const year = moment(date).format("YYYY");
  let filter = {};
  switch (gap) {
    case "lack_of_extraction_of_hours":
      filter = {
        $and: [
          { scheduled_minutes_diff: { $lt: 0 } },
          { year: { $eq: +year } },
          { month: { $eq: +month } },
        ],
      };
      break;
    case "providing_overtime":
      filter = {
        $and: [
          { scheduled_minutes_diff: { $gt: 0 } },
          { year: { $eq: +year } },
          { month: { $eq: +month } },
        ],
      };
      break;
    case "unapproved_minutes":
      filter = {
        $and: [
          { approved_minutes_diff: { $lt: 0 } },
          { year: { $eq: +year } },
          { month: { $eq: +month } },
        ],
      };
      break;
    case "ok2go":
      filter = {
        $and: [
          // { approved_minutes_diff: { $lt: 0 } },
          // { year: { $eq: +year } },
          // { month: { $eq: +month } },
        ],
      };
      break;

    case "overlaps":
      filter = {
        // $and: [{ year: { $eq: +year } }, { month: { $eq: +month } }],
      };
      break;

    default:
      filter = {};
      break;
  }

  return {
    ...filter,
    freeText,
  };
};

const getResorce = (gap) => {
  let resource = {};
  switch (gap) {
    case "lack_of_extraction_of_hours":
      resource = "order_summaries";
      break;
    case "providing_overtime":
      resource = "order_summaries";
      break;
    case "unapproved_minutes":
      resource = "order_summaries";
      break;
    case "ok2go":
      resource = "ok2go";
      break;
    case "overlaps":
      resource = "overlaps";
      break;
    default:
      resource = "order_summaries";
      break;
  }

  return resource;
};

const getQuery = (gap, date) => {
  const month = moment(date).format("M");
  const year = moment(date).format("YYYY");
  let query = {};
  switch (gap) {
    case "unapproved_minutes":
      query = { unapproved_minutes: true };
      break;
    case "overlaps":
      query = { year: +year, month: +month };
      break;
    default:
      query = {};
      break;
  }
  return query;
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().gaps.page));
};
